<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <p>
          Edit {{ timeRecord.user.first_name }} {{ timeRecord.user.last_name }}
          time record for {{ timeRecord.job_number.job_number }} for
          {{ timeRecord.date | tinyDate }}
        </p>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-12 medium-3">
        <label>Hours</label>
        <select v-model="timeRecord.hours">
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
          <option value="24">24</option>
        </select>
      </div>
      <div class="cell small-12 medium-3">
        <label>Minutes</label>
        <select v-model="timeRecord.minutes">
          <option value="0">0</option>
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="45">45</option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-3">
        <label>
          <input type="checkbox" v-model="timeRecord.commando" />
          Commando Upload?
        </label>
      </div>
      <div class="cell small-9">
        <button @click="updateTime" class="button green small">Save</button>
        &nbsp;
        <button @click="$emit('cancelled')" class="button red small">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'EditTime',
  props: [
    'recordId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
    username() {
      return this.$store.state.username;
    },
  },
  data() {
    return {
      timeRecord: {
        user: {
          first_name: '',
          last_name: '',
        },
        job_number: {
          job_number: '',
        },
      },
    };
  },
  methods: {
    getRecord() {
      axios.get(`/timeRecords/getOne/${this.recordId}.json?token=${this.token}`)
        .then((response) => {
          this.timeRecord = response.data.record;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateTime() {
      axios.post(`/timeRecords/update .json?token=${this.token}`, this.timeRecord)
        .then((response) => {
          this.$emit('timeEdited', response.data.record);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getRecord();
  },
};
</script>
