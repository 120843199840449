<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <p>{{ username }} recording time against {{ jobNumber.job_number }}</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-12 medium-3">
        <label>Week</label>
        <select v-model="timeRecord.week">
          <option value="1">This week</option>
          <option value="2">Last week</option>
        </select>
      </div>
      <div class="cell small-12 medium-3">
        <label>Day</label>
        <select v-model="timeRecord.day">
          <option value="1">Mon</option>
          <option value="2">Tue</option>
          <option value="3">Wed</option>
          <option value="4">Thu</option>
          <option value="5">Fri</option>
        </select>
      </div>
      <div class="cell small-12 medium-3">
        <label>Hours</label>
        <select v-model="timeRecord.hours">
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
          <option value="24">24</option>
        </select>
      </div>
      <div class="cell small-12 medium-3">
        <label>Minutes</label>
        <select v-model="timeRecord.minutes">
          <option value="0">0</option>
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="45">45</option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-3">
        <label>
          <input type="checkbox" v-model="timeRecord.commando" />
          Commando Upload?
        </label>
      </div>
      <div class="cell small-9">
        <button @click="saveTime" class="button green small">Save</button>
        &nbsp;
        <button @click="$emit('cancelled')" class="button red small">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'RecordTime',
  props: [
    'jobId',
    'jobNumberId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
    username() {
      return this.$store.state.username;
    },
  },
  data() {
    return {
      jobNumber: {},
      timeRecord: {
        commando: 0,
        day: 1,
        hours: 0,
        minutes: 0,
        week: 1,
      },
    };
  },
  methods: {
    saveTime() {
      const postData = {};
      postData.timeRecord = this.timeRecord;
      postData.timeRecord.job_id = this.jobId;
      postData.timeRecord.job_number_id = this.jobNumberId;
      postData.timeRecord.user_id = this.userid;
      axios.post(`/timeRecords/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('timeSaved', response.data.timeRecord);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNumber() {
      axios.get(`/jobNumbers/getOne/${this.jobNumberId}.json?token=${this.token}`)
        .then((response) => {
          this.jobNumber = response.data.jobNumber;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setDay() {
      const date = new Date();
      this.timeRecord.day = date.getDay();
    },
  },
  mounted() {
    this.setDay();
    this.getNumber();
  },
};
</script>
