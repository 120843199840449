<template>
  <div class="grid-container fluid">
    <div class="grid-x grid-padding-x pt-4">
      <div class="cell small-2">
        <h5 class="text-right">Timesheet for:</h5>
      </div>
      <div class="cell small-3">
        <select
          v-model="changedUser">
          <option
            v-for="(user, index) in users"
            :key="`us_${index}`"
            :value="user.id">
            {{ user.name }}
          </option>
        </select>
      </div>
      <div class="cell small-6 text-center">
        <button v-if="prevWeek === false" @click="changeDate('prev')" class="button small">
          Prev
        </button>
        <button v-else @click="todaysDate()" class="button small">
          This week
        </button>
        &nbsp;
        <span>W/C {{ weekCommencing }} {{ date | tinyDate }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-4">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>Mon</th>
              <th>Tue</th>
              <th>Wed</th>
              <th>Thu</th>
              <th>Fri</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span v-for="(time, index) in monTime" :key="`mt_${index}`">
                  {{ time.hours }}:{{ time.minutes }}
                  {{ time.job_number.job_number }} /
                  {{ time.job_number.description.substring(0, 50) }}
                  <span v-if="time.commando" class="commando-indicator">
                    Commando
                  </span>
                  <span
                    style="position: relative; top: 7px;"
                    @click="editRecord(index, 'mon')"
                    title="Update time record"
                    class="material-symbols-outlined icon-yellow icon-link"
                  >edit</span>
                  <span
                    style="position: relative; top: 7px;"
                    @click="viewJob(index, 'mon')"
                    title="Update time record"
                    class="material-symbols-outlined icon-green icon-link"
                  >visibility</span>
                  <br />
                </span>
              </td>
              <td>
                <span v-for="(time, index) in tueTime" :key="`tt_${index}`">
                  {{ time.hours }}:{{ time.minutes }}
                  {{ time.job_number.job_number }} /
                  {{ time.job_number.description.substring(0,50) }}
                  <span v-if="time.commando" class="commando-indicator">
                    Commando
                  </span>
                  <span
                    style="position: relative; top: 7px;"
                    @click="editRecord(index, 'tue')"
                    title="Update time record"
                    class="material-symbols-outlined icon-yellow icon-link"
                  >edit</span>
                  <span
                    style="position: relative; top: 7px;"
                    @click="viewJob(index, 'tue')"
                    title="Update time record"
                    class="material-symbols-outlined icon-green icon-link"
                  >visibility</span>
                  <br />
                </span>
              </td>
              <td>
                <span v-for="(time, index) in wedTime" :key="`wt_${index}`">
                  {{ time.hours }}:{{ time.minutes }}
                  {{ time.job_number.job_number }} /
                  {{ time.job_number.description.substring(0,50) }}
                  <span v-if="time.commando" class="commando-indicator">
                    Commando
                  </span>
                  <span
                    style="position: relative; top: 7px;"
                    @click="editRecord(index, 'wed')"
                    title="Update time record"
                    class="material-symbols-outlined icon-yellow icon-link"
                  >edit</span>
                  <span
                    style="position: relative; top: 7px;"
                    @click="viewJob(index, 'wed')"
                    title="Update time record"
                    class="material-symbols-outlined icon-green icon-link"
                  >visibility</span>
                  <br />
                </span>
              </td>
              <td>
                <span v-for="(time, index) in thuTime" :key="`tht_${index}`">
                  {{ time.hours }}:{{ time.minutes }}
                  {{ time.job_number.job_number }} /
                  {{ time.job_number.description.substring(0,50) }}
                  <span v-if="time.commando" class="commando-indicator">
                    Commando
                  </span>
                  <span
                    style="position: relative; top: 7px;"
                    @click="editRecord(index, 'thu')"
                    title="Update time record"
                    class="material-symbols-outlined icon-yellow icon-link"
                  >edit</span>
                  <span
                    style="position: relative; top: 7px;"
                    @click="viewJob(index, 'thu')"
                    title="Update time record"
                    class="material-symbols-outlined icon-green icon-link"
                  >visibility</span>
                  <br />
                </span>
              </td>
              <td>
                <span v-for="(time, index) in friTime" :key="`ft_${index}`">
                  {{ time.hours }}:{{ time.minutes }}
                  {{ time.job_number.job_number }} /
                  {{ time.job_number.description.substring(0,50) }}
                  <span v-if="time.commando" class="commando-indicator">
                    Commando
                  </span>
                  <span
                    style="position: relative; top: 7px;"
                    @click="editRecord(index, 'fri')"
                    title="Update time record"
                    class="material-symbols-outlined icon-yellow icon-link"
                  >edit</span>
                  <span
                    style="position: relative; top: 7px;"
                    @click="viewJob(index, 'fri')"
                    title="Update time record"
                    class="material-symbols-outlined icon-green icon-link"
                  >visibility</span>
                  <br />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-12">
        <h5>Record time</h5>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-12 medium-3">
        <label>Client</label>
        <select v-model="searchClient">
          <option
            v-for="(client, index) in clientList"
            :key="`c_${index}`"
            :value="client.id">{{ client.name }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-3">
        <label>Brand</label>
        <select v-model="searchBrand">
          <option
            v-for="(brand, index) in brandList"
            :key="`br_${index}`"
            :value="brand.id">{{ brand.name }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-3">
        <label>Job Number</label>
        <input type="text" v-model="searchJobNumber">
      </div>
    </div>
    <div v-if="jobNumbers.length > 0" class="grid-x grid-padding-x pt-1">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Description</th>
              <th class="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(jobNumber, index) in jobNumbers" :key="`j_${index}`">
              <td>{{ jobNumber.job_number }}</td>
              <td>{{ jobNumber.description }}</td>
              <td class="text-right">
                <button
                  @click="selectedJobNumber = jobNumber.id"
                  class="button small">Add hours</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <AddHours
      @timeSaved="showTimeSaved"
      @cancelled="selectedJobNumber = 0"
      :jobNumberId="selectedJobNumber"
      v-if="selectedJobNumber > 0"/>
    <EditHours
      @timeEdited="timeEdited"
      @cancelled="indexInEdit = -1"
      :recordId="recordInEdit"
      v-if="indexInEdit > -1" />
  </div>
</template>

<script>
import { format } from 'date-fns';
import AddHours from './time-records/components/RecordTime.vue';
import EditHours from './time-records/components/EditTime.vue';
import axios from '../axios';

export default {
  name: 'Timesheets',
  components: {
    AddHours,
    EditHours,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
    username() {
      return this.$store.state.username;
    },
  },
  data() {
    return {
      client: {
        id: 0,
        brands: [],
      },
      clientList: [],
      changedUser: 0,
      brandList: [],
      date: null,
      firstLoad: false,
      friTime: [],
      jobNumbers: [],
      monTime: [],
      prevWeek: false,
      recordInEdit: 0,
      indexInEdit: -1,
      dayInEdit: '',
      searchBrand: 999,
      searchClient: 0,
      searchJobNumber: '',
      selectedJobNumber: 0,
      thuTime: [],
      tueTime: [],
      users: [],
      wedTime: [],
      weekCommencing: '',
      years: [18, 19, 20, 21, 22, 23, 24],
    };
  },
  watch: {
    changedUser() {
      this.getTimeRecords();
    },
    date() {
      this.getTimeRecords();
    },
    searchBrand() {
      this.setSearchParams();
      this.search();
    },
    searchClient(value) {
      if (this.firstLoad === true) {
        const client = this.clientList.filter((clientVal) => clientVal.id === value)[0];
        if (client) {
          // eslint-disable-next-line
          const brands = client.brands;
          brands.unshift({
            id: 999,
            clientId: client.id,
            name: 'All',
          });
          this.brandList = brands;
          this.setSearchParams();
          this.search();
        }
      }
    },
    searchJobNumber() {
      this.setSearchParams();
      this.search();
    },
  },
  methods: {
    editRecord(index, day) {
      let record = null;
      if (day === 'mon') { record = this.monTime[index]; }
      if (day === 'tue') { record = this.tueTime[index]; }
      if (day === 'wed') { record = this.wedTime[index]; }
      if (day === 'thu') { record = this.thuTime[index]; }
      if (day === 'fri') { record = this.friTime[index]; }
      this.recordInEdit = record.id;
      this.indexInEdit = index;
      this.dayInEdit = day;
    },
    viewJob(index, day) {
      let record = null;
      if (day === 'mon') { record = this.monTime[index]; }
      if (day === 'tue') { record = this.tueTime[index]; }
      if (day === 'wed') { record = this.wedTime[index]; }
      if (day === 'thu') { record = this.thuTime[index]; }
      if (day === 'fri') { record = this.friTime[index]; }
      axios.get(`/estimates/getIdByJobNumber/${record.job_number_id}.json?token=${this.token}`)
        .then((response) => {
          this.$router.push(`/estimates/view/${response.data.estimateId}`);
        });
    },
    timeEdited(value) {
      if (this.dayInEdit === 'mon') {
        this.monTime[this.indexInEdit].hours = value.hours;
        this.monTime[this.indexInEdit].minutes = value.minutes;
        this.monTime[this.indexInEdit].commando = value.commando;
      }
      if (this.dayInEdit === 'tue') {
        this.tueTime[this.indexInEdit].hours = value.hours;
        this.tueTime[this.indexInEdit].minutes = value.minutes;
        this.tueTime[this.indexInEdit].commando = value.commando;
      }
      if (this.dayInEdit === 'wed') {
        this.wedTime[this.indexInEdit].hours = value.hours;
        this.wedTime[this.indexInEdit].minutes = value.minutes;
        this.wedTime[this.indexInEdit].commando = value.commando;
      }
      if (this.dayInEdit === 'thu') {
        this.thuTime[this.indexInEdit].hours = value.hours;
        this.thuTime[this.indexInEdit].minutes = value.minutes;
        this.thuTime[this.indexInEdit].commando = value.commando;
      }
      if (this.dayInEdit === 'fri') {
        this.friTime[this.indexInEdit].hours = value.hours;
        this.friTime[this.indexInEdit].minutes = value.minutes;
        this.friTime[this.indexInEdit].commando = value.commando;
      }
      this.recordInEdit = 0;
      this.indexInEdit = -1;
      this.dayInEdit = '';
    },
    showTimeSaved(value) {
      const recordDate = new Date(value.date);
      const day = recordDate.getDay();
      if (day === 1) this.monTime.push(value);
      if (day === 2) this.tueTime.push(value);
      if (day === 3) this.wedTime.push(value);
      if (day === 4) this.thuTime.push(value);
      if (day === 5) this.friTime.push(value);
      this.selectedJobNumber = 0;
    },
    changeDate(direction) {
      this.prevWeek = true;
      const time = Date.now(this.date);
      let newDate = '';
      if (direction === 'prev') {
        const prevTime = time - (86400000 * 7);
        newDate = new Date(prevTime);
      } else {
        const nextTime = time + (86400000 * 7);
        newDate = new Date(nextTime);
      }
      const monday = this.getMonday(newDate);
      this.date = format(monday, 'yyyy-MM-dd');
    },
    todaysDate() {
      this.prevWeek = false;
      const date = new Date();
      const monday = this.getMonday(date);
      this.date = format(monday, 'yyyy-MM-dd');
    },
    getMonday(d) {
      const dy = new Date(d);
      const day = dy.getDay();
      const diff = dy.getDate() - day + (day === 0 ? -6 : 1);
      return new Date(d.setDate(diff));
    },
    setSearchParams() {
      const searchValues = {};
      searchValues.brand = this.searchBrand;
      searchValues.client = this.searchClient;
      searchValues.jobNumber = this.searchJobNumber;
      this.$store.commit('setSearch', searchValues);
    },
    updateSearchParams() {
      this.searchClient = this.$store.state.searchClient;
      this.searchBrand = this.$store.state.searchBrand;
      this.searchJobNumber = this.$store.state.searchJobNumber;
      const clientId = this.searchClient;
      let client = {};
      for (let i = 0; i < this.clientList.length; i += 1) {
        if (this.clientList[i].id === clientId) {
          client = this.clientList[i];
        }
      }
      this.brandList = client.brands;
    },
    getSearchLists() {
      axios.get(`/searches/getJobSearchList.json?token=${this.token}`)
        .then((response) => {
          this.clientList = response.data.clients;
          this.updateSearchParams();
          this.firstLoad = true;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getTimeRecords() {
      this.clearTime();
      const postData = {};
      postData.userId = this.userid;
      postData.changedUser = this.changedUser;
      postData.date = this.date;
      axios.post(`/timeRecords/getByUser.json?token=${this.token}`, postData)
        .then((response) => {
          this.monTime = response.data.monTime;
          this.tueTime = response.data.tueTime;
          this.wedTime = response.data.wedTime;
          this.thuTime = response.data.thuTime;
          this.friTime = response.data.friTime;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    clearTime() {
      this.monTime = [];
      this.tueTime = [];
      this.wedTime = [];
      this.thuTime = [];
      this.friTime = [];
    },
    getUsers() {
      axios.get(`/users/getList.json?token=${this.token}`)
        .then((response) => {
          this.users = response.data.users;
          this.changedUser = this.userid;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    search() {
      this.jobNumbers = [];
      const postData = {};
      postData.brand = this.searchBrand;
      postData.client = this.searchClient;
      postData.number = this.searchNumber;
      postData.year = this.searchYear;
      postData.jobNumber = this.searchJobNumber;
      axios.post(`/searches/generalSearch.json?token=${this.token}`, postData)
        .then((response) => {
          this.jobNumbers = response.data.jobNumbers;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    setDate() {
      const date = new Date();
      const monday = this.getMonday(date);
      this.date = format(new Date(monday), 'yyyy-MM-dd');
    },
  },
  mounted() {
    setTimeout(() => {
      this.getUsers();
      this.getSearchLists();
      this.setDate();
    }, 100);
  },
};
</script>
